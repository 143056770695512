/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { FormGroup, FormControl } from '@angular/forms';

export interface Action {
  label: string;
  name: string;
  callback: Observable<any>;
  dismiss: boolean;
  disable: boolean;
  secondary: boolean;
  noDisable: boolean;
}

interface Link {
  label: string;
  callback: Observable<any>;
}

@Component({
  selector: 'lfx-confirmation-modal',
  templateUrl: './confirmation-without-headers.component.html',
  styleUrls: ['./confirmation-without-headers.component.scss'],
})
export class ConfirmationModalWithoutHeadersComponent implements OnInit {
  @Input() title = '';
  @Input() headerTitle = '';
  @Input() message = '';
  @Input() messageIcon;
  @Input() question;
  @Input() note;
  @Input() errorMessage;
  @Input() showClosePopupButton = true;
  @Input() showHeader: boolean;

  @Input() inputs = [];
  @Input() actions: Action[] = [];
  @Input() footerMessage;
  @Input() footerLink: Link;
  @Input() additionalTemplate;
  @Output() dismisscallback: any;

  inputsForm: FormGroup;
  loadingButtons: any;

  constructor() {}

  ngOnInit() {
    if (this.inputs) {
      const formVariables = {};

      this.inputs.forEach(input => {
        formVariables[input.name] = new FormControl(
          input.value,
          input.validators
        );
      });

      this.inputsForm = new FormGroup(formVariables);
    }

    if (this.actions && this.actions.length > 0) {
      this.loadingButtons = {};

      this.actions.forEach(action => {
        this.loadingButtons[action.name] = false;
      });
    }
  }

  perform(action) {
    if (action.callback) {
      // Show loader
      this.loadingButtons[action.name] = true;
      action.disable = true;
      // Run callback function
      let callbackObservable;

      if (this.inputs && this.inputs.length > 0) {
        this.inputsForm.markAllAsTouched();

        if (this.inputsForm.invalid) {
          this.loadingButtons[action.name] = false;
          action.disable = false;

          return;
        }
        const formData = this.inputsForm.value;

        callbackObservable = action.callback(formData);
      } else {
        callbackObservable = action.callback();
      }

      // Subscribe on function observaber to update loading state and dismiss popup if required.
      if (callbackObservable && callbackObservable.subscribe) {
        callbackObservable.subscribe(res => {
          this.changePopupLoading(action);
        });
      } else {
        this.changePopupLoading(action);
      }
    } else if (action.dismiss) {
      // Close the popup
      this.closePopup();
    }
  }

  performFooterAction(action) {
    if (action) {
      action().subscribe(res => {
        this.closePopup();
      });
    }
  }

  changePopupLoading(action) {
    // Hide loader
    this.loadingButtons[action.name] = false;
    action.disable = false;

    if (action.dismiss) {
      // Close the popup
      this.closePopup();
    }
  }

  closePopup() {
    this.dismisscallback();
  }
}
