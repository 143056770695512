<div *ngIf="showHeader" class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ headerTitle }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="closePopup()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <button type="button" class="close" aria-label="Close" *ngIf="showClosePopupButton" (click)="closePopup()">
    <span aria-hidden="true">&times;</span>
  </button>

  <div class="text-center main-container">
    <div class="title mt-10">{{ title }}</div>

    <div class="component-padding text mt-10 text-danger" *ngIf="errorMessage">
      {{ errorMessage }}
    </div>

    <div class="component-padding text mt-10 question" *ngIf="question">
      {{ question }}
    </div>

    <div class="component-padding text mt-10">
      <i class="icon {{messageIcon}}" *ngIf="messageIcon"></i>
      <!-- {{ message }} -->
      <p [innerHTML]="message"></p>
    </div>

    <div class="component-padding text mt-10" *ngIf="note">
      {{ note }}
    </div>

    <!-- additional template -->
    <ng-container *ngIf="additionalTemplate">
      <ng-container *ngTemplateOutlet="additionalTemplate"></ng-container>
    </ng-container>

    <div class="text-center justify-content-center mt-5" *ngIf="inputs && inputs.length > 0">
      <div class="form" [formGroup]="inputsForm">
        <div class="row justify-content-center" *ngFor="let input of inputs">
          <div class="form-group col-8" >
            <input class="form-control input"
              id="{{input.name}}"
              formControlName="{{input.name}}"
              type="{{input.type}}"
              placeholder="{{input.placeholder}}"
              required>
            <ng-container *ngIf="inputsForm.get(input.name) as inputController">
              <p class="text-danger text-left" *ngIf="inputController && inputController.errors && (inputController.dirty || inputController.touched)">{{input.errorMessage}}</p>
            </ng-container>
          </div>
        </div>

        <div class="row component-padding text-center main-container justify-content-center">
          <div *ngFor="let action of actions">
            <button type="submit" class="button" [class.secondary]="action.secondary" [class.disabled]="(inputsForm.invalid || action.disable) && !action.noDisable" (click)="perform(action)" [disabled]="action.disable && !action.noDisable">
              <i class="fa fa-spinner fa-spin pl-2" *ngIf="loadingButtons[action.name]"></i>
              {{ action.label }}
            </button>
          </div>
        </div>
      </div>
    </div>


    <div class="row component-padding text-center main-container justify-content-center" *ngIf="!inputs || (inputs && inputs.length === 0)">
      <div *ngFor="let action of actions">
        <i class="fa fa-spinner fa-spin pl-2" *ngIf="loadingButtons[action.name]"></i>
        <button class="button" [class.secondary]="action.secondary" [class.disabled]="action.disable" (click)="perform(action)" [disabled]="action.disable">
          <i class="fa fa-spinner fa-spin pl-2" *ngIf="loadingButtons[action.name]"></i>
          {{ action.label }}
        </button>
      </div>
    </div>
</div>

<div id="content" *ngIf="footerMessage">
  <h1 class="divider"> OR </h1>
</div>


<div class="" *ngIf="footerMessage">
  <div class="text-center main-container">
    <div class="text mt-10" [innerHTML]="footerMessage"></div>

    <div class="component-padding text mt-10" *ngIf="footerLink && footerLink.callback && footerLink.label">
      <span class="link" (click)="performFooterAction(footerLink.callback)">
        {{ footerLink.label }}
      </span>
    </div>
  </div>
</div>
